/* @media print {
    .tableContent {
        width: fit-content;
        height: 100% !important;
        overflow-y: visible;
    }
} */

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        color: "black" !important;
    }

    th,
    tr {
        color: "black" !important;
    }
    .onlyShowPrint {
        display: table-row !important;
    }
    .MuiTableCell-root {
        color: "black" !important;
    }
}

.onlyShowPrint {
    display: none;
}